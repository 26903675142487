label {
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
  color: #000000;
  font-family: -apple-system , BlinkMacSystemFont , 'Segoe UI' , Roboto , 'Helvetica Neue' , Arial , 'Noto Sans' , 'Liberation Sans' , sans-serif , 'Apple Color Emoji' , 'Segoe UI Emoji' , 'Segoe UI Symbol' , 'Noto Color Emoji';
}

input {
  padding: 5px;
  width: 50.0%;
  font-size: 18px;
  height: 50px;
  line-height: 1.5;
  border-radius: 8px;
  display: block;
  font-weight: 400;
  color: #495057;
  background-color: #f4f4f4;
  border: none;
  margin: 0;
  font-family: -apple-system , BlinkMacSystemFont , 'Segoe UI' , Roboto , 'Helvetica Neue' , Arial , 'Noto Sans' , 'Liberation Sans' , sans-serif , 'Apple Color Emoji' , 'Segoe UI Emoji' , 'Segoe UI Symbol' , 'Noto Color Emoji';
}

input:invalid {
  color: #e10808;
  border-color: #e10808;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-left: 8px;
}
